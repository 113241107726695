<template>
  <v-app class="ss-intel">
    <slot />
  </v-app>

  <!-- <v-app class="ss-intel">
    <v-main class="ss-advisor-dash__main">
      <v-toolbar
        flat
        color="rgba(240, 242, 245, 0.8)"
        class=""
        style="position: sticky; top: 0; z-index: 1; backdrop-filter: blur(4px)"
      >
        <v-row>
          <v-col class="d-flex col-sm-6 col-12">
            <v-breadcrumbs :items="breadCrumbs" class="pl-2">
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  v-if="item.text === 'Dashboard'"
                  :href="item.href"
                  :disabled="item.disabled"
                >
                  <v-icon class="mr-2">mdi-home</v-icon> Dashboard
                </v-breadcrumbs-item>
                <v-breadcrumbs-item
                  v-else
                  :href="item.href"
                  :disabled="item.disabled"
                >
                  {{ item.text }}
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>
          </v-col>
          <v-col
            class="
              d-flex
              align-center
              justify-end
              col-sm-6 col-12
              text-sm-right
            "
          >
            <v-responsive max-width="300" class="mr-4">
              <v-text-field
                dense
                flat
                hide-details
                rounded-sm
                outlined
                clearable
                label="Search"
              ></v-text-field>
            </v-responsive>

            <v-btn icon x-small class="mr-4">
              <v-icon>mdi-cog</v-icon>
            </v-btn>

            <v-btn icon x-small class="mr-4">
              <v-icon>mdi-bell</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-toolbar>
      <div class="container px-6 pb-6 container--fluid">
      
      </div>
    </v-main>

    <v-footer color="transparent" height="72" inset style="padding-left: 250px">
      <p class="mb-0">
        <small
          >© {{ currentYear }} The Secure Companies, Inc. All rights
          reserved.</small
        >
      </p>
    </v-footer>
  </v-app> -->
</template>

<script>
import { API, graphqlOperation } from "aws-amplify";
import { getAdvisor } from "@/graphql/queries";

export default {
  data: () => ({
    loading: true,
  }),
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },
  created() {
    console.log("applayout intel created");
  },
  methods: {
    async handleCheckAdvisor(advisorRouteId) {
      this.loading = true;
      try {
        if (advisorRouteId) {
          // Check if advisorId is valid UUID

          const regex = new RegExp(
            /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/
          );

          const isValidRegex = await regex.test(advisorRouteId);
          if (isValidRegex) {
            // check if advisor exists
            const response = await API.graphql(
              graphqlOperation(getAdvisor, { id: advisorRouteId })
            );
            const advisor = response.data.getAdvisor;

            if (advisor) {
              this.advisorId = advisor.id;
            }
          } else {
            throw new Error("A valid Advisor is required to view this page.");
          }
        } else {
          throw new Error("A valid Advisor is required to view this page.");
        }
      } catch (error) {
        console.log("Error", error);
      } finally {
        this.loading = false;
      }
    },

    // getNestedRoute(routePath) {
    //   const advisorId = this.$route.params.id;
    //   return `/intel/${advisorId}/${routePath}`;
    // },
  },
};
</script>
<style lang="scss">
.ss-intel {
  &.theme--light.v-application {
    background-color: #f0f2f5;
  }

  &__main {
    @media screen and (min-width: 1264px) {
      /* padding-left: 274px !important; */
    }
  }

  &__nav-drawer {
    max-width: 15.625rem !important;
    /* height: calc(100% - 2rem) !important; */
  }

  .theme--dark {
    .v-list-item--active,
    .v-list-item--active:hover {
      color: var(--v-primary-base);

      .v-list-item__title {
        font-weight: bold;
      }

      &:before {
        opacity: 0;
      }
    }
  }
}
</style>
